import Vue from 'vue';
import {
    Button,
    Toast,
    Tab,
    Tabs,
    Empty,
    Icon,
    Skeleton,
    DatetimePicker,
    Popup,
    Search,
    Col,
    Row,
    Overlay,
    Loading,
    Sticky,
    Notify
} from 'vant';

Vue.use(Notify);
Vue.use(Sticky);
Vue.use(Loading);
Vue.use(Overlay);
Vue.use(Col);
Vue.use(Row);
Vue.use(Search);
Vue.use(Popup);
Vue.use(DatetimePicker);
Vue.use(Skeleton);
Vue.use(Icon);
Vue.use(Empty);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Toast);
Vue.use(Button)