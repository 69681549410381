<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { domTitle, setDocumentTitle } from '@/utils/domUtil'
export default {
  methods: {
    upDateTitle() {
      // 更新标题
      console.log(domTitle)
      const { title } = this.$route.meta
      title && setDocumentTitle(`${domTitle}` + '-' + `${title}`)
    }
  },
  watch: {
    $route(val) {
      this.upDateTitle(val)
    }
  }
}
</script>
<style lang="less" scoped>
#app {
  height: 100%;
}
</style>