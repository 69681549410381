import config from '@/config/config'
import dd from 'dingtalk-jsapi'; // 此方式为整体加载，也可按需进行加载
export const setDocumentTitle = function (title) {
  document.title = title // 动态根据路由配置修改标题
  const ua = navigator.userAgent
  // eslint-disable-next-line
  const regex = /\bMicroMessenger\/([\d\.]+)/
  if (regex.test(ua) && /ip(hone|od|ad)/i.test(ua)) {
    const i = document.createElement('iframe')
    i.src = '/favicon.ico'
    i.style.display = 'none'
    i.onload = function () {
      setTimeout(function () {
        i.remove()
      }, 9)
    }
    document.body.appendChild(i)
  }
  // 判断是否是钉钉中打开设置标题
  if (dd.env.platform !== "notInDingTalk") {
    dd.ready(function () {
      dd.biz.navigation.setTitle({
        title: title,
      })
    })
  }
}

export const domTitle = config.title
