import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '产品总览'
    },
    component: () => import(/* webpackChunkName: "Home" */ '../views/home/Home.vue')
  },
  {
    path: '/details',
    name: 'Details',
    meta: {
      title: '产品详情'
    },
    component: () => import(/* webpackChunkName: "details" */ '../views/details/Details.vue')
  },
  {
    path: '/search',
    name: 'Search',
    meta: {
      title: '搜索产品'
    },
    component: () => import(/* webpackChunkName: "search" */ '../views/search/Search.vue')
  },
  {
    path: '/edit',
    name: 'GuanZhuEdit',
    meta: {
      title: '编辑关注产品'
    },
    component: () => import(/* webpackChunkName: "edit" */ '../views/edit/GuanZhuEdit.vue')
  },
  {
    path: '/alldetails',
    name: 'AllDetails',
    meta: {
      title: '产品详情'
    },
    component: () => import(/* webpackChunkName: "details" */ '../views/alldetails/AllDetails.vue')
  },
  {
    path: '/topreels',
    name: 'TopReels',
    meta: {
      title: 'TopReels'
    },
    component: () => import(/* webpackChunkName: "details" */ '../views/topreels/TopReels.vue')
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

// 解决Loading chunk failed
router.onError(error => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    router.replace(targetPath)
  }
})

export default router
