import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 自定义导入模块
import './core/user'
import './global.less'

// 模拟数据
// mock
// WARNING: `mockjs` NOT SUPPORT `IE` PLEASE DO NOT USE IN `production` ENV.
// import './mock'

Vue.config.productionTip = false

/**
 * 格式化万位
 * @param {*} value // 值
 * @param {*} n //保留小数位
 * @returns 
 */
Vue.filter('million', function (value, n) {
  let num;
  if (value > 9999) { //大于9999显示x.xx万
    num = (Math.floor(value / 100) / 100).toFixed(2);
  } else if (value <= 9999 && value > -9999) {
    n ? num = parseFloat(value).toFixed(n) : num = value
  } else if (value < -9999) { //小于-9999显示-x.xx万
    num = -(Math.floor(Math.abs(value) / 1000) / 10).toFixed(2)
  }
  return num;
})

/**
 *  千位分隔符,不处理小数
 * @param {*} num // 值
 * @returns 
 */
Vue.filter('priceInit', function (num) {
  var source = String(num).split(".");
  source[0] = source[0].replace(new RegExp('(\\d)(?=(\\d{3})+$)', 'ig'), "$1,");
  return source.join(".")
})

/**
 *  补零
 * @param {*} num // 值
 * @returns 
 */
Vue.filter('fillZero', function (num) {
  let value = Math.round(parseFloat(num) * 100) / 100;
  const s = value.toString().split(".");
  if (s.length == 1) {
    value = value.toString() + ".00";
    return value;
  }
  if (s.length > 1) {
    if (s[1].length < 2) {
      value = value.toString() + "0";
    }
    return value;
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
